.link {
  display: inline-block;
  color: var(--marketplaceColor);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
